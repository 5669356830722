import React,{ useEffect, useState } from 'react';
import './dashboard.css';
import 'react-datepicker/dist/react-datepicker.css';

// export function FilterList({ title, list, textKey, valueKey, value, filterKey, onChange }) {
//     const [open, setOpen] = useState(false);
//     const [searchTerm, setSearchTerm] = useState("");

//     const checkboxClick = (item) => {
//         console.log("Checkbox Clicked:", item[valueKey], value);
//         if (filterKey === "publication") {
//             if (value.find(x => item[valueKey] === x)) {
//                 onChange(filterKey, "");
//             } else {
//                 onChange(filterKey, item[valueKey]);
//             }
//         } else {
//             if (value.find(x => item[valueKey] === x)) {
//                 onChange(filterKey, value.filter(x => x !== item[valueKey]));
//             } else {
//                 onChange(filterKey, [...value, item[valueKey]]);
//             }
//         }
//     };

//     return (
//         <div className="container cc tit">
//             <div className={open ? 'sidebar-item open' : 'sidebar-item'}>
//                 <div className="sidebar-title" onClick={() => setOpen(!open)}>
//                     <span className='sp'>{title}</span>
//                     <i className="bi-chevron-down toggle-btn"></i>
//                 </div>
//                 <div className="sidebar-content">
//                     <input
//                         type="text"
//                         placeholder="Search filter"
//                         className="sifil"
//                         onChange={(event) => setSearchTerm(event.target.value)}
//                     />
//                     {list.filter((co) => {
//                         if (searchTerm === "") return co;
//                         return co.name.toLowerCase().includes(searchTerm.toLowerCase());
//                     }).map((item) => (
//                         <tr key={item[valueKey]} className="sid">
//                             <td>
//                                 <label>
//                                     <input
//                                         type="checkbox"
//                                         id={item[textKey]}
//                                         name={item[textKey]}
//                                         checked={value.includes(item[valueKey])}
//                                         value={item[textKey]}
//                                         onChange={() => checkboxClick(item)}
//                                         className="checkbox"
//                                     />
//                                     {item[textKey]}
//                                 </label>
//                             </td>
//                         </tr>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// }



export function FilterList({ title, list, textKey, valueKey, value, filterKey, onChange }) {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const checkboxClick = (item) => {
        console.log("Checkbox Clicked:", item[valueKey], value);
        if (filterKey === "publication") {
            if ((value || []).find(x => item[valueKey] === x)) {
                onChange(filterKey, "");
            } else {
                onChange(filterKey, item[valueKey]);
            }
        } else {
            if ((value || []).find(x => item[valueKey] === x)) {
                onChange(filterKey, value.filter(x => x !== item[valueKey]));
            } else {
                onChange(filterKey, [...(value || []), item[valueKey]]);
            }
        }
    };

    return (
        <div className="container cc tit">
            <div className={open ? 'sidebar-item open' : 'sidebar-item'}>
                <div className="sidebar-title" onClick={() => setOpen(!open)}>
                    <span className='sp'>{title}</span>
                    <i className="bi-chevron-down toggle-btn"></i>
                </div>
                <div className="sidebar-content">
                    <input
                        type="text"
                        placeholder="Search filter"
                        className="sifil"
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                    {(list || []).filter((co) => {
                        if (searchTerm === "") return co;
                        return co.name.toLowerCase().includes(searchTerm.toLowerCase());
                    }).map((item) => (
                        <tr key={item[valueKey]} className="sid">
                            <td>
                                <label>
                                    <input
                                        type="checkbox"
                                        id={item[textKey]}
                                        name={item[textKey]}
                                        checked={(value || []).includes(item[valueKey])}
                                        value={item[textKey]}
                                        onChange={() => checkboxClick(item)}
                                        className="checkbox"
                                    />
                                    {item[textKey]}
                                </label>
                            </td>
                        </tr>
                    ))}
                </div>
            </div>
        </div>
    );
}

// Set default props to avoid undefined errors
FilterList.defaultProps = {
    value: [],
    list: [],
};
