import React, { useState } from 'react';

const LegalFormFilter = ({ onFilterChange }) => {
    const legalForms = [
        "Einzelunternehmen", "Kollektivgesellschaft", "Aktiengesellschaft", "Genossenschaft",
        "Gesellschaft mit beschränkter Haftung", "Verein", "Stiftung", "Istitut des öffentlichen Rechts",
        "Zweigniederlassung", "Kommanditgesellschaft", "Zweigniederlassung auslänische Gesellschaft"
    ];

    const [selectedLegalForms, setSelectedLegalForms] = useState([]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        let updatedSelectedLegalForms;

        if (checked) {
            updatedSelectedLegalForms = [...selectedLegalForms, value];
        } else {
            updatedSelectedLegalForms = selectedLegalForms.filter(form => form !== value);
        }

        setSelectedLegalForms(updatedSelectedLegalForms);
        onFilterChange('companyType', updatedSelectedLegalForms); // Ensure the correct key is used
    };

    return (
        <li className="dropright sidebar-item nh">
            <a href="#" className="dropdown-toggle sidebar-title" data-toggle="dropdown">
                <i className="bi bi-flag cit"></i> Legal Form
            </a>
            <div className="dropdown-menu">
                <ul className="list-unstyled">
                    {legalForms.map(form => (
                        <li className="drop" key={form}>
                            <label>
                                <input 
                                    type="checkbox" 
                                    value={form} 
                                    onChange={handleCheckboxChange} 
                                    checked={selectedLegalForms.includes(form)} 
                                />
                                {form}
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

export default LegalFormFilter;
