// api.js
const BASE_URL = 'https://api.kseco.eu/api'; 

export const BANCKROPT_ACTIVE_COMPANIES = {
  getBanckroptActiveCompanies: `${BASE_URL}/active-bankrupt-companies`,
  getBanckroptActiveCompaniesByDateRange: (startDate, endDate) =>
    `${BASE_URL}/active-bankrupt-companies_by_range?start_at=${encodeURIComponent(startDate)}&end_at=${encodeURIComponent(endDate)}`,
};

export const CANTON_API = {
  getAllCantons: `${BASE_URL}/cantons`,
  getCantonById: (id) => `${BASE_URL}/cantons/${id}`,
};

export const COMPANY_API = {
  getAllCompanies: `${BASE_URL}/companies`,
  getAlbanianCompanies: `${BASE_URL}/companies/albanianCompanies`,
  getTodayPublication: `${BASE_URL}/companies/todayPublication`,
  countAllCompanies: `${BASE_URL}/companies/countAllCompanies`,
  countAllTodayPublications: `${BASE_URL}/companies/countAllTodayPublications`,
  countTodayPublications: `${BASE_URL}/companies/countTodayPublications`,
  countAlbanianCompanies: `${BASE_URL}/companies/countAlbanianCompanies`,
  countMonthlyAllAndAlbanianCompanies: `${BASE_URL}/companies/countAL`,
  getCompanyById: (id) => `${BASE_URL}/companies/${id}`,


};

export const COMPANY_TYPE_API = {
  getAllCompanyTypes: `${BASE_URL}/companiesType`,
};

export const FILTER_API = {
  filterByCanton: `${BASE_URL}/filter/canton`,
  filterByLegalForm: `${BASE_URL}/filter/legalform`,
  filterByMunicipality: (key) => `${BASE_URL}/filter/search/${key}`,
  filterByDate: (date) => `${BASE_URL}/filter/date/${date}`,
};

export const HOME_API = {
  getHomeData: `${BASE_URL}/home`,
  countAllCompanies: `${BASE_URL}/home/countAllCompanies`,
  countAllTodayPublications: `${BASE_URL}/home/countAllTodayPublications`,
  countAlbanianTodayPublications: `${BASE_URL}/home/countAlbanianTodayPublications`,
  countAlbanianCompanies: `${BASE_URL}/home/countAlbanianCompanies`,
  get10Companies: `${BASE_URL}/home/10Companies`,
  getMonthlyRegistration: `${BASE_URL}/home/monthlyRegistration`,
  getMonthlyCompanies: `${BASE_URL}/home/monthlyCompanies`,
  getCompanyByCanton: `${BASE_URL}/home/companyByCanton`,
};

export const NATIONALITY_API = {
  getAllNationalities: `${BASE_URL}/nationalities`,
};

export const SECTOR_API = {
  getAllSectors: `${BASE_URL}/sectors`,
};

export const SERVICES_API = {
  getAllServices: `${BASE_URL}/services`,
};




//OTHER IMPORTANT APIS TO USE



// https://api.kseco.eu/api/companies?name=Swisscann

// https://api.kseco.eu/api/companies?services=Verlag

// https://api.kseco.eu/api/companies?purposes=Verwaltung

// https://api.kseco.eu/api/companies?companyType%5B%5D=Einzelunternehmen

// https://api.kseco.eu/api/active-bankrupt-companies_by_range?start_at=2018-01-18&end_at=2023-01-18

// https://api.kseco.eu/api/owner-companies ne body kjo api e merr ID ne e rreshtit per pronar dhe i kthen kompanite e tjerea te pronarirt