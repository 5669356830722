import React from 'react';
import './CustomCheckbox.css'; // Import the custom CSS file

const CustomCheckbox = ({ label, isSelected, onCheckboxChange }) => {
    return (
        <div className="custom-checkbox-container" onClick={onCheckboxChange}>
            <div className={`custom-checkbox ${isSelected ? 'selected' : ''}`}>
                {isSelected && (
                    <span className="checkmark">✓</span>
                )}
            </div>
            <span className="custom-checkbox-label">{label}</span>
        </div>
    );
};

export default CustomCheckbox;
