// import { useMemo } from "react";

// export function CustomPagination({ totalData, currentPage, itemsPerPage, onPageChange }) {
//   const totalPages = useMemo(() => Math.ceil(totalData / itemsPerPage), [totalData, itemsPerPage]);

//   const pageNumbers = useMemo(() => {
//     const pages = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pages.push(i);
//     }
//     return pages;
//   }, [totalPages]);

//   return (
//     <div className="flex justify-center items-center mt-4 space-x-2">
//       <button
//         onClick={() => onPageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         className="px-4 py-2 text-sm bg-gray-200 text-gray-600 rounded hover:bg-gray-300 disabled:opacity-50"
//       >
//         Previous
//       </button>

//       {pageNumbers.map((number) => (
//         <button
//           key={number}
//           onClick={() => onPageChange(number)}
//           className={`px-4 py-2 text-sm rounded ${
//             currentPage === number ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
//           }`}
//         >
//           {number}
//         </button>
//       ))}

//       <button
//         onClick={() => onPageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         className="px-4 py-2 text-sm bg-gray-200 text-gray-600 rounded hover:bg-gray-300 disabled:opacity-50"
//       >
//         Next
//       </button>
//     </div>
//   );
// }

import React, { useMemo } from "react";

const CustomPagination = ({ totalData, currentPage, itemsPerPage, onPageChange }) => {
    const totalPages = useMemo(() => Math.ceil(totalData / itemsPerPage), [totalData, itemsPerPage]);
  
    const pageNumbers = useMemo(() => {
      const pages = [];
      const maxPagesToShow = 5;
      let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
      let endPage = startPage + maxPagesToShow - 1;
  
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(endPage - maxPagesToShow + 1, 1);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }, [totalPages, currentPage]);
  
    if (totalPages === 0) return null;
  
    return (
        <div className="flex justify-center items-center mb-4 space-x-2">
        {/* Previous Button */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-300 ${
            currentPage === 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
          }`}
        >
          &larr; Previous
        </button>
  
        {/* Page Numbers */}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-300 ${
              currentPage === number
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            }`}
          >
            {number}
          </button>
        ))}
  
        {/* Next Button */}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-300 ${
            currentPage === totalPages
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
          }`}
        >
          Next &rarr;
        </button>
      </div>
    );
  };
  
export default CustomPagination;