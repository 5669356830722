import React, {useState } from 'react'
import Chart from 'react-apexcharts';

const ApexChart=() => {
    const [options] = useState({
        chart: {
            height: 350,
            type: 'area'
          },
          colors : ['#8ab3e4', '#21538f'],
          fill: {
            type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }},
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          
          xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
          },
    })

    const [series] = useState([{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
      }, {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41]
    }])
    

      return (
        

  <div id="chart">
<Chart options={options} series={series} type="area" height={400} width={600} />
</div>


      );
    }

export default ApexChart;