import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const ApexChart = () => {
  const [companyCounts, setCompanyCounts] = useState({ active: 0, bankrupt: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyCounts = async () => {
      try {
        const startDate = '2018-01-18';
        const endDate = '2024-01-18';
        const response = await axios.get(`https://api.kseco.eu/api/active-bankrupt-companies?start_at=${startDate}&end_at=${endDate}`);
        const { active_companies_count, bankrupt_companies_count } = response.data;
        setCompanyCounts({ active: active_companies_count, bankrupt: bankrupt_companies_count });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchCompanyCounts();
  }, []);

  const { active, bankrupt } = companyCounts;

  const series = [active, bankrupt];

  const options = {
    labels: ['Active Companies', 'Bankrupt Companies'],
    theme: {
      monochrome: {
        enabled: true
      }
    },
    fill: {
      colors: ['#6895cc', '#366094']
    },
    noData: { text: "Empty Data" },
  };

  return (
    <Chart
      type="pie"
      width={500}
      height={450}
      series={loading ? [] : series}
      options={options}
    />
  );
};

export default ApexChart;
