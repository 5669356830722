/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// eslint-disable-next-line

import React, { useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import CustomPagination from "./CustomPagination";

import Button from '@mui/material/Button'; 

import FilteredTable from "./FilteredTable";

import { COMPANY_API } from "../../api/api";

import axios from "axios";

import useDebounce from "../useDebounce";
export function Publication({
  currentPage,
  setCurrentPage,
  filter = {},      
  cleanedFilter = {},  
  setFilter,
  setSearchTerm,
  Link,
  bot,
  Pagination,
  totalData,
  searchTerm,
  tableRef,
  handleExcel,
  a,
  loading,
  // companies,
  companies: externalCompanies,
  filterChanged,

  getCompanies,
  dateRange
}) {


  // States for non-publication filters
  const [itemCanton, setItemCanton] = useState(filter.canton || []);
  const [itemLegal, setItemLegal] = useState(filter.companyType || []);
  const [itemNationality, setItemNationality] = useState(filter.nationality || []);
  const [itemSectors, setItemSectors] = useState(filter.purposes || []);
  const [itemServices, setItemServices] = useState(filter.services || []);
  const [itemSearchTerm, setItemSearchTerm] = useState(searchTerm || '');

  const debouncedSearchTerm = useDebounce(itemSearchTerm, 300);

  const [localDateRange, setLocalDateRange] = useState(dateRange);
  // Function to calculate the date range
  const calculateDateRange = (companiesList) => {
    if (companiesList.length === 0) return { startDate: null, endDate: null };
    
    const updatedAtDates = companiesList.map((company) => new Date(company.updatedAt));
    const startDate = new Date(Math.min(...updatedAtDates));
    const endDate = new Date(Math.max(...updatedAtDates));

    return { startDate, endDate };
  };

  useEffect(() => {
    setItemCanton([...filter.canton || []]);
  }, [filter.canton]);

  useEffect(() => {
    setItemLegal([...filter.companyType || []]);
  }, [filter.companyType]);

  useEffect(() => {
    setItemNationality([...filter.nationality || []]);
  }, [filter.nationality]);

  useEffect(() => {
    setItemSectors([...filter.purposes || []]);
  }, [filter.purposes]);

  useEffect(() => {
    setItemServices([...filter.services || []]);
  }, [filter.services]);

  useEffect(() => {
    setItemSearchTerm(searchTerm || '');
  }, [searchTerm]);

  const UnSelectAllFilters = () => {
    setItemCanton([]);
    setItemLegal([]);
    setItemNationality([]);
    setItemSectors([]);
    setItemServices([]);
    setFilter({
      ...filter,
      canton: [],
      companyType: [],
      nationality: [],
      purposes: [],
      services: []
    });
  };

  const handleDelete = (type, chipToDelete) => () => {
    const updateFilter = (key, value) => setFilter({ ...filter, [key]: value });
    
    switch (type) {
      case 'canton':
        const updatedCantons = itemCanton.filter((chip) => chip !== chipToDelete);
        setItemCanton(updatedCantons);
        updateFilter('canton', updatedCantons);
        break;
      case 'companyType':
        const updatedLegal = itemLegal.filter((chip) => chip !== chipToDelete);
        setItemLegal(updatedLegal);
        updateFilter('companyType', updatedLegal);
        break;
      case 'nationality':
        const updatedNationality = itemNationality.filter((chip) => chip !== chipToDelete);
        setItemNationality(updatedNationality);
        updateFilter('nationality', updatedNationality);
        break;
      case 'purposes':
        const updatedSectors = itemSectors.filter((chip) => chip !== chipToDelete);
        setItemSectors(updatedSectors);
        updateFilter('purposes', updatedSectors);
        break;
      case 'services':
        const updatedServices = itemServices.filter((chip) => chip !== chipToDelete);
        setItemServices(updatedServices);
        updateFilter('services', updatedServices);
        break;
      default:
        break;
    }
  };


  const [companies, setCompanies] = useState([]);
  const [totalCompanies, setTotalCompanies] = useState(0);

  const getCompaniesBySearchTerm = async (searchTerm) => {
    try {
      const token = localStorage.getItem("TOKEN");
      let endpoint = `https://api.kseco.eu/api/companies`;

      const params = {
        name: searchTerm || '',
      };

      console.log("API Call Parameters:", params); 
      const response = await axios.get(endpoint, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCompanies(response.data.data)
      setTotalCompanies(response.data.meta.total);

      const range = calculateDateRange(response.data.data);
      setLocalDateRange(range);
    } catch (error) {
      console.error('Error fetching companies by search term:', error);
      setCompanies([]); 
      setTotalCompanies(0); 
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat('en-GB').format(date); // Example: "dd/mm/yyyy"
  };

  useEffect(() => {
    if (filter.searchTerm) {
      getCompaniesBySearchTerm(filter.searchTerm);
    } else {
      setCompanies([]); 
      setTotalCompanies(0); 
    }
  }, [filter.searchTerm]);


  // const updateText = (event) => {
  //   const value = event.target.value || '';
  //   setFilter((prevFilter) => ({
  //     ...prevFilter,
  //     searchTerm: value,
  //     publication: filter.publication || 'All Publications', 
  //   })); 
  // };

  const updateText = (event) => {
    const value = event.target.value || '';
    setFilter((prevFilter) => ({
      ...prevFilter,
      searchTerm: value,
      publication: prevFilter.publication || 'All Publications', // Ensures 'publication' is properly set
    }));
  };

  
  const handlePublicationChange = (selectedPublication) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      publication: [selectedPublication],
    }));
  };
  
  useEffect(() => {
    getCompanies(currentPage); 
  }, [filter, currentPage]);
  

  const updateSearchTerm = (term) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      searchTerm: term,
    }));
  };
  

  const convertedFilter = {
    canton: itemCanton,
    companyType: itemLegal,
    nationality: itemNationality,
    purposes: itemSectors,
    services: itemServices,
  };

  const publicationFilter = cleanedFilter.publication || 'No Publication Selected';

  return (
    <section className="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
      <p className="resu">Filter Results</p>
      {/* <p>Data from {formatDate(dateRange.startDate)} - {formatDate(dateRange.endDate)}</p> */}
      <p>Data from {formatDate(filter.searchTerm ? localDateRange.startDate : dateRange.startDate)} - {formatDate(filter.searchTerm ? localDateRange.endDate : dateRange.endDate)}</p>
      <div className="paraqitja">
        <div className="publication">
          <div className="filter"><b>Publication:</b>{publicationFilter}</div>
        </div>
        <div>
          <div className="filter"><b>Canton:</b>
            {itemCanton.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('canton', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Legal Form:</b>
            {itemLegal.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('companyType', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Nationality:</b>
            {itemNationality.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('nationality', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Sectors:</b>
            {itemSectors.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('purposes', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>Services:</b>
            {itemServices.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('services', data)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="ser">
        <input
          className="ch"
          type="text"
          placeholder="&#xF52A; Search Companies"
          value={filter.searchTerm}
          onChange={updateText}
        />
        <button onClick={handleExcel} className="exc">
          <img src={a} alt="Excel Export" />
        </button>
      </div>

      {/* <FilteredTable
        companies={companies}
        searchTerm={itemSearchTerm}
        convertedFilter={convertedFilter}
        publicationFilter={publicationFilter}
        loading={loading}
        tableRef={tableRef}
      /> */}

      <FilteredTable
        companies={filter.searchTerm ? companies : externalCompanies} // Use filtered companies or fallback
        searchTerm={itemSearchTerm}
        convertedFilter={convertedFilter}
        publicationFilter={publicationFilter}
        loading={loading}
        tableRef={tableRef}
      />

      <div className="my-3">
        <CustomPagination
          totalData={totalData}
          currentPage={currentPage}
          itemsPerPage={10}
          onPageChange={setCurrentPage}
        />
      </div>
    </section>
  );
}

export default Publication;

// export function Publication({
//   currentPage,
//   setCurrentPage,
//   filter = {},       
//   cleanedFilter = {},  
//   setFilter,
//   setSearchTerm,
//   Link,
//   bot,
//   Pagination,
//   totalData,
//   searchTerm,
//   tableRef,
//   handleExcel,
//   a,
//   loading,
//   companies,
//   filterChanged
// }) {
//   // States for non-publication filters
//   const [itemCanton, setItemCanton] = useState(filter.canton || []);
//   const [itemLegal, setItemLegal] = useState(filter.companyType || []);
//   const [itemNationality, setItemNationality] = useState(filter.nationality || []);
//   const [itemSectors, setItemSectors] = useState(filter.purposes || []);
//   const [itemServices, setItemServices] = useState(filter.services || []);
//   const [itemSearchTerm, setItemSearchTerm] = useState(searchTerm || '');


  

//   // Handle non-publication filters
//   useEffect(() => {
//     setItemCanton([...filter.canton || []]);
//   }, [filter.canton]);

//   useEffect(() => {
//     setItemLegal([...filter.companyType || []]);
//   }, [filter.companyType]);

//   useEffect(() => {
//     setItemNationality([...filter.nationality || []]);
//   }, [filter.nationality]);

//   useEffect(() => {
//     setItemSectors([...filter.purposes || []]);
//   }, [filter.purposes]);

//   useEffect(() => {
//     setItemServices([...filter.services || []]);
//   }, [filter.services]);

//   useEffect(() => {
//     setItemSearchTerm(searchTerm || '');
//   }, [searchTerm]);

//   const UnSelectAllFilters = () => {
//     setItemCanton([]);
//     setItemLegal([]);
//     setItemNationality([]);
//     setItemSectors([]);
//     setItemServices([]);
//     setFilter({
//       ...filter,
//       canton: [],
//       companyType: [],
//       nationality: [],
//       purposes: [],
//       services: []
//     });
//   };


//   //testing this code

//   const updateFilterState = () => {
//     // Combine all filters into a single state object
//     const updatedFilter = {
//       canton: itemCanton || [],
//       companyType: itemLegal || [],
//       nationality: itemNationality || [],
//       purposes: itemSectors || [],
//       services: itemServices || [],
//       city: itemSearchTerm || ''
//   };

//     // Update the filter
//     setFilter(updatedFilter);

//     // Call the API to fetch the filtered companies
//     fetchFilteredCompanies(updatedFilter);
//   };


//   const fetchFilteredCompanies = async (filter) => {
//     try {
//         // Retrieve the token from localStorage
//         const token = localStorage.getItem("TOKEN");

//         // Construct the URL with query parameters
//         let url = COMPANY_API.getAllCompanies;
//         let params = [];
//         if (filter.canton && filter.canton.length) params.push(`canton=${encodeURIComponent(filter.canton.join(','))}`);
//         if (filter.companyType && filter.companyType.length) {
//             filter.companyType.forEach(type => {
//                 params.push(`companyType[]=${encodeURIComponent(type)}`);
//             });
//         }
//         if (filter.nationality && filter.nationality.length) params.push(`nationality=${encodeURIComponent(filter.nationality.join(','))}`);
//         if (filter.purposes && filter.purposes.length) params.push(`purposes=${encodeURIComponent(filter.purposes.join(','))}`);
//         if (filter.services && filter.services.length) params.push(`services=${encodeURIComponent(filter.services.join(','))}`);
//         if (filter.city) params.push(`name=${encodeURIComponent(filter.city)}`);
//         if (params.length) url += '?' + params.join('&');

//         // Fetch the filtered companies with authorization header
//         const response = await fetch(url, {
//             method: 'GET',
//             headers: {
//                 'Authorization': `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             }
//         });

//         // Check if the response status is OK (status code 200)
//         if (!response.ok) {
//             const errorDetails = await response.text();
//             console.error(`Error fetching companies. Status: ${response.status}, Details: ${errorDetails}`);
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         // Parse the response as JSON
//         const companiesData = await response.json();
//         companies(companiesData);
        
//     } catch (error) {
//         console.error('Error fetching filtered companies:', error);
//     }
// };

//   // Call updateFilterState whenever filters change
//   useEffect(() => {
//     updateFilterState();
//   }, [itemCanton, itemLegal, itemNationality, itemSectors, itemServices, itemSearchTerm]);

//   const handleDelete = (type, chipToDelete) => () => {
//     const updateFilter = (key, value) => setFilter({ ...filter, [key]: value });
    
//     switch (type) {
//       case 'canton':
//         const updatedCantons = itemCanton.filter((chip) => chip !== chipToDelete);
//         setItemCanton(updatedCantons);
//         updateFilter('canton', updatedCantons);
//         break;
//       case 'companyType':
//         const updatedLegal = itemLegal.filter((chip) => chip !== chipToDelete);
//         setItemLegal(updatedLegal);
//         updateFilter('companyType', updatedLegal);
//         break;
//       case 'nationality':
//         const updatedNationality = itemNationality.filter((chip) => chip !== chipToDelete);
//         setItemNationality(updatedNationality);
//         updateFilter('nationality', updatedNationality);
//         break;
//       case 'purposes':
//         const updatedSectors = itemSectors.filter((chip) => chip !== chipToDelete);
//         setItemSectors(updatedSectors);
//         updateFilter('purposes', updatedSectors);
//         break;
//       case 'services':
//         const updatedServices = itemServices.filter((chip) => chip !== chipToDelete);
//         setItemServices(updatedServices);
//         updateFilter('services', updatedServices);
//         break;
//       default:
//         break;
//     }

//     updateFilterState();
//   };

//   const updateText = (event) => {
//     const value = event.target.value || ''; 
//     setItemSearchTerm(value);
//     setFilter({ ...filter, city: value });
//   };

//   // Prepare filters to be applied
//   const convertedFilter = {
//     canton: itemCanton,
//     companyType: itemLegal,
//     nationality: itemNationality,
//     purposes: itemSectors,
//     services: itemServices,
//   };

//   // Ensure publication filtering uses the cleanedFilter
//   const publicationFilter = cleanedFilter.publication || 'No Publication Selected';

//   return (
//     <section className="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
//       <p className="resu">Filter Results</p>
//       <p>Data from 29.06.2022 - 16.09.2022</p>
//       <div className="paraqitja">
//         <div className="publication">
//           <div className="filter"><b>Publication:</b>{publicationFilter}</div>
//         </div>
//         <div>
//           <div className="filter"><b>Canton:</b>
//             {itemCanton.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('canton', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Legal Form:</b>
//             {itemLegal.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('companyType', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Nationality:</b>
//             {itemNationality.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('nationality', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Sectors:</b>
//             {itemSectors.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('purposes', data)}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <div className="filter"><b>Services:</b>
//             {itemServices.map(data => (
//               <Chip
//                 key={data}
//                 label={data}
//                 onDelete={handleDelete('services', data)}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//       <div className="ser">
//         <input
//           className="ch"
//           type="text"
//           placeholder="&#xF52A; Search Companies"
//           value={itemSearchTerm}
//           onChange={updateText}
//         />
//         <button onClick={handleExcel} className="exc">
//           <img src={a} alt="Excel Export" />
//         </button>
//       </div>
//       {/* <FilteredTable
//         companies={companies}
//         searchTerm={itemSearchTerm}
//         convertedFilter={convertedFilter}
//         publicationFilter={publicationFilter}
//         loading={loading}
//         tableRef={tableRef}
//       /> */}

//       <FilteredTable
//         companies={companies}
//         searchTerm={itemSearchTerm}
//         convertedFilter={{
//           canton: itemCanton,
//           companyType: itemLegal,
//           nationality: itemNationality,
//           purposes: itemSectors,
//           services: itemServices
//         }}
//         publicationFilter={cleanedFilter.publication || 'No Publication Selected'}
//         loading={loading}
//         tableRef={tableRef}
//       />
//       <div className="my-3">
//         <CustomPagination
//           totalData={totalData}
//           currentPage={currentPage}
//           itemsPerPage={10}
//           onPageChange={setCurrentPage}
//         />
//       </div>
//     </section>
//   );
// }


