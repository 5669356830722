import React  from 'react';
import axios from 'axios';
import './register.css';
import Navbar from "../Navbar/Navbar";


const Registration = () => {

return(

<div>
<Navbar />
<div>
<div class="d-lg-flex half">
    <div class="bg order-1 order-md-2">

    <div id="wrapper">
    <div class="container">
        <div class="">
              <div class="col-md-3">
                  <div class="circle c1 img-circle">
                      <h4 class="blue">Basic Plan</h4>
                      <span class="icona blue"><i class="fa fa-eur"></i></span>
                      <span class="price-large blue">8</span>
                      <span class="price-small">,90</span>
                      <p>Great for small Business</p>
                      <button type="button" class="btn btn-info choos">Choose</button>
                  </div>
              </div>
          
              <div class="col-md-3">
                  <div class="circle c2 img-circle">
                      <h4 class="yellow">Starter Plan</h4>
                      <span class="icona yellow"><i class="fa fa-eur"></i></span>
                      <span class="price-large yellow">24</span>
                      <span class="price-small">,90</span>
                      <p>Great for small Business</p>
                      <button type="button" class="btn btn-warning choos">Choose</button>
                  </div>
              </div>
          
          <div class="col-md-3">
                  <div class="circle c3 img-circle">
                      <h4 class="green">Premier Plan</h4>
                      <span class="icona green"><i class="fa fa-eur"></i></span>
                      <span class="price-large green">54</span>
                      <span class="price-small">,90</span>
                      <p>Great for small Business</p>
                      <button type="button" class="btn btn-success choos">Choose</button>
                  </div>
              </div>

        </div>
    </div>
</div>
    </div>
    <div class="contents order-2 order-md-1">

      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-7 py-5">
            <h3>Register</h3>
            <p class="mb-4">Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.</p>
            <form action="#" method="post">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group first">
                    <label for="fname">First Name</label>
                    <input type="text" class="form-control" placeholder="e.g. John" id="fname"/>
                  </div>    
                </div>
                <div class="col-md-6">
                  <div class="form-group first">
                    <label for="lname">Last Name</label>
                    <input type="text" class="form-control" placeholder="e.g. Smith" id="lname"/>
                  </div>    
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group first">
                    <label for="email">Email Address</label>
                    <input type="email" class="form-control" placeholder="e.g. john@your-domain.com" id="email"/>
                  </div>    
                </div>
              </div>
              <div class=" row">
              <div class="col-md-12">
              <label for="email">Gender</label>
                                    <div class="form-group first radio">            
                                        <div class="form-radio-item">
                                        <label classname="cash">
                                            <input type="radio" name="payment" id="cash" />
                                            Male</label>
                                            <span class="check"></span>
                                        </div>
                                        <div class="form-radio-item">
                                        <label  classname="cash">
                                            <input type="radio" name="payment" id="cheque"/>
                                            Female</label>
                                            <span class="check"></span>
                                        </div>
                                    </div>
                                </div>
                        </div>
              <div class="row">
                <div class="col-md-6">
                <div class="form-group first">
                    <label for="lname">Country</label>
                    <input type="text" class="form-control" placeholder="e.g. Switzerland" id="lname"/>
                  </div>     
                </div>
                <div class="col-md-6">
                <div class="form-group first">
                    <label for="lname">Zip code</label>
                    <input type="text" class="form-control" placeholder="e.g 40000" id="lname"/>
                  </div>     
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                <div class="form-group first">
                    <label for="lname">City</label>
                    <input type="text" class="form-control" placeholder="e.g. Bern" id="lname"/>
                  </div>     
                </div>
                <div class="col-md-6">
                <div class="form-group first">
                    <label for="lname">Phone Number</label>
                    <input type="text" class="form-control" placeholder="+00 0000 000 0000" id="lname"/>
                  </div>     
                </div>
              </div>
              <div class=" row">
              <div class="col-md-12">
              <label for="email">Choose a Package</label>
                                    <div class="form-group first rad">            
                                        <div class="form-radio-item">
                                        <label  classname="cash">
                                            <input type="radio" name="payment" id="cash" />
                                            STANDARD - 45$</label>
                                            <span class="check"></span>
                                        </div>
                                        <div class="form-radio-item">
                                        <label  classname="cash">
                                            <input type="radio" name="payment" id="cheque"/>
                                            MEDIUM - 45$</label>
                                            <span class="check"></span>
                                        </div>
                                        <div class="form-radio-item">
                                        <label classname="cash">
                                            <input type="radio" name="payment" id="cheque"/>
                                            PREMIUM - 45$</label>
                                            <span class="check"></span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="row">
                <div class="col-md-12">
                  <div class="form-group first">
                    <label for="email">Card Owner</label>
                    <input type="email" class="form-control" placeholder="e.g. john@your-domain.com" id="email"/>
                  </div>    
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
              
                  <div class="form-group last mb-3">
                    <label>Card Number</label>
                    <div className='visa'>
                    <input  class="form-control" placeholder="" id="password" /> <span class="kartat"> <i class="fa fa-cc-visa mx-1"></i> <i class="fa fa-cc-mastercard mx-1"></i> </span>
                  </div>
                  </div>
                </div>
                <div class="col-md-4">
              
                  <div class="form-group last mb-3">
                    <label>CVV</label>
                    <input class="form-control" placeholder="" id="re-password"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
              
                  <div class="form-group last mb-3">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" placeholder="Your Password" id="password"/>
                  </div>
                </div>
                <div class="col-md-6">
              
                  <div class="form-group last mb-3">
                    <label for="re-password">Re-type Password</label>
                    <input type="password" class="form-control" placeholder="Your Password" id="re-password"/>
                  </div>
                </div>
              </div>
              
              <div class="d-flex mb-5 mt-4 align-items-center">
                <div class="d-flex align-items-center">
                <label class="control control--checkbox mb-0"><span class="caption">Creating an account means you're okay with our <a href="#">Terms and Conditions</a> and our <a href="#">Privacy Policy</a>.</span>
                  <input type="checkbox"/>
                  <div class="control__indicator"></div>
                </label>
              </div>
              </div>
              <button type="button" class="btn btn-outline-success regis">Register now</button>
              

            </form>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</div>
</div>
);
};
export default Registration