import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    try {
      const login = await axios.post('https://api.kseco.eu/api/login', {
        email: email,
        password: password,
      });
      localStorage.setItem('TOKEN', login.data.token);
      navigate('/home');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(!isOpen);
  };



  return (
    <div className="popup-box">
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <div className="login">
        <div className="form">
          <span className="close-icon" onClick={handleClose}>
            x
          </span>
          <form className="login-form" onSubmit={Auth}>
            <p className="has-text-centered">{msg}</p>
            <span className="material-icons">
              <i className="fa fa-lock"></i>
            </span>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button>Login</button>
          </form>
        </div>
      </div>
    </section>
  </div>
  );
};

export default Login;
