/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import axios from 'axios';
import './details.css';
import Navbar from '../Navbar/Navbar';
import 'jspdf-autotable';
import { Graph } from "react-d3-graph";
import {CustomNode} from 'react';
import { COMPANY_API, SECTOR_API, COMPANY_TYPE_API } from '../../api/api';
import { DataSet, Network } from 'vis-network/standalone';

import { NotificationContext } from '../Details/NotificationContext';
// function Details() {


//   const [data, setData] = useState({
//     nodes: [],
//     links: [],
//   });
  
//   const [details, setDetails] = useState({});
//   const [persons, setPersons] = useState([]);
//   const [purposes, setPurposes] = useState([]);
//   const [addresses, setAddresses] = useState([]);
//   const [dateOfActs, setDateOfActs] = useState([]);
//   const [remarks, setRemarks] = useState([]);
//   const [publications, setPublications] = useState([]);
//   const [additionalPayments, setAdditionalPayments] = useState([]);
//   const [company, setCompany] = useState({});
//   const [sectors, setSectors] = useState([]);



//   const [purpose, setPurpose] = useState("");

//   const [currentCompany, setCurrentCompany] = useState({});
  
//   const { id } = useParams();

//   const getDetails = async () => {
//     const token = localStorage.getItem('TOKEN');
//     try {
//       const response = await axios.get(COMPANY_API.getCompanyById(id), {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       const data = response.data.data || {};
//       setDetails(data);
//       setPersons(data.persons || []);
//       setCompany(data);

//       // Set initial nodes and links for the graph based on company and persons
//       const initialNodes = [
//         {
//           id: data.companyName || 'Company Name',
//           svg: data.logo || "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
//           fontSize: 16
//         },
//         ...(data.persons || [])
//           .filter(item => item && item.activeDataPerson && !item.personalData?.includes('CHE'))
//           .map((item, index) => ({
//             id: `${item?.personalData || 'Unknown'}-${index}`,
//             svg: "https://cdn-icons-png.flaticon.com/512/3033/3033143.png",
//             size: 400,
//             fontSize: 12
//           })),
//         ...(data.persons || [])
//           .filter(item => item && item.activeDataPerson && item.personalData?.includes('CHE'))
//           .map((item, index) => ({
//             id: `${item?.personalData || 'Unknown'}-${index}-filtered`,
//             svg: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
//             size: 400,
//             fontSize: 12
//           }))
//       ];

//       const initialLinks = [
//         ...(data.persons || [])
//           .filter(item => item && item.activeDataPerson && !item.personalData?.includes('CHE'))
//           .map((item, index) => ({
//             source: data.companyName || 'Company Name',
//             target: `${item?.personalData || 'Unknown'}-${index}`
//           })),
//         ...(data.persons || [])
//           .filter(item => item && item.activeDataPerson && item.personalData?.includes('CHE'))
//           .map((item, index) => ({
//             source: data.companyName || 'Company Name',
//             target: `${item?.personalData || 'Unknown'}-${index}-filtered`
//           }))
//       ];

//       // Update the graph data
//       setData({ nodes: initialNodes, links: initialLinks });

//     } catch (error) {
//       console.error('Failed to fetch company details:', error);
//     }
//   };

//   const getSectors = async () => {
//     try {
//       const response = await axios.get(SECTOR_API.getAllSectors);
//       setSectors(response.data || []);
//     } catch (error) {
//       console.error('Failed to fetch sectors:', error);
//     }
//   };

//   const inferCompanyPurpose = () => {
//     if (!details || !details.sectorId || !sectors.length) {
//       setPurpose("Purpose information is not available.");
//       return;
//     }

//     const sector = sectors.find(sector => String(sector.id) === String(details.sectorId));
//     if (sector) {
//       setPurpose(`Sector: ${sector.name}`);
//     } else {
//       setPurpose("Purpose information is not available.");
//     }
//   };

//   useEffect(() => {
//     getDetails();
//     getSectors();
//   }, [id]);

//   useEffect(() => {
//     inferCompanyPurpose();
//   }, [sectors, details]);

//   // Handle updating the network when a new owner is clicked
//   const updateNetworkWithNewCompanies = (companies, ownerPersonalData) => {
//     const newNodes = companies.map((company, index) => ({
//       id: `${company.companyName || 'Unknown'}-${index}-company`,
//       svg: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
//       size: 400,
//       fontSize: 12,
//     }));

//     const newLinks = companies.map((company, index) => ({
//       source: ownerPersonalData,
//       target: `${company.companyName || 'Unknown'}-${index}-company`,
//     }));

//     setData(prevData => ({
//       nodes: [...prevData.nodes, ...newNodes],
//       links: [...prevData.links, ...newLinks],
//     }));
//   };

//   const handleNodeClick = async (nodeId) => {
//     const personalData = nodeId?.split('-')[0]; // Ensure personalData is extracted safely
  
//     if (!personalData) {
//       console.error('Invalid nodeId or personalData is missing:', nodeId);
//       return;
//     }
  
//     const requestBody = { owner: personalData };
  
//     try {
//       const token = localStorage.getItem('TOKEN');
//       const response = await axios.post('https://api.kseco.eu/api/owner-companies', requestBody, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
  
//       const companies = response.data.data || [];
//       if (companies.length > 0) {
//         updateNetworkWithNewCompanies(companies, personalData);
//       } else {
//         console.log('No companies found for this owner.');
//       }
//     } catch (error) {
//       console.error('Failed to fetch companies for the owner:', error);
//     }
//   };
  
  
//   const myConfig = {
//     nodeHighlightBehavior: true,
//     maxZoom: 2,
//     minZoom: 0.5,
//     panAndZoom: true, // Allow panning and zooming
//     staticGraph: false, // Enable dragging of nodes
//     collapsible: false,
//     height: 600,
//     width: 1000,
//     d3: {
//       alphaTarget: 0.05,
//       gravity: -250,
//       linkLength: 200,
//       linkStrength: 2,
//     },
//     node: {
//       color: "lightgreen",
//       size: 700,
//       highlightStrokeColor: "blue",
//       mouseCursor: "pointer",
//       opacity: 1,
//       strokeColor: "none",
//       strokeWidth: 1.5,
//       labelProperty: 'id',
//       highlightFontSize: 14,
//       labelPosition: 'bottom',
//       svg: "",
//       symbolType: "circle",
//     },
//     link: {
//       color: "#d3d3d3",
//       opacity: 1,
//       strokeWidth: 4,
//       highlightColor: "red",
//     },
//     onClickNode: handleNodeClick,
//     onClickLink: (source, target) => {
//       console.log(`Clicked link between ${source} and ${target}`);
//     },
//   };
  

function Details() {
  const [data, setData] = useState({ nodes: [], links: [] });
  const [details, setDetails] = useState({}); 
  const [sectors, setSectors] = useState([]); 
  const [company, setCompany] = useState({}); 
  const [sectorInfo, setSectorInfo] = useState(""); 
  const [currentCompany, setCurrentCompany] = useState({});
  const { id } = useParams(); 
  const containerRef = React.useRef(null);
  let networkInstance = null; 
  const [inferredSectors, setInferredSectors] = useState([]);
  const [activeDateId, setActiveDateId] = useState(null);

  const { addNotification } = useContext(NotificationContext);
  
const toggleActiveDate = (clickedItem) => {
  console.log(`Toggling Date of Acts for: ${clickedItem.dateOfActs}`);

  const isActive = clickedItem.activeDataOfActs;

  try {
    if (isActive) {
      
      setActiveDateId(null);
      setDetails(prevDetails => {
        console.log('Resetting details:', prevDetails);
        return {
          ...prevDetails,
          shares: prevDetails.shares.map(item => ({ ...item, activeDataShare: false })),
          capitals: prevDetails.capitals.map(item => ({ ...item, activeDataCapital: false })),
          registrationoffices: prevDetails.registrationoffices.map(item => ({ ...item, activeDataRegistration: false })),
          addresses: prevDetails.addresses.map(item => ({ ...item, activeDataAddress: false })),
          purposes: prevDetails.purposes.map(item => ({ ...item, activeDataPurpose: false })),
          remarks: prevDetails.remarks.map(item => ({ ...item, activeDataRemark: false })),
          cities: prevDetails.cities.map(item => ({ ...item, activeDataCity: false })),
          persons: prevDetails.persons.map(item => ({ ...item, activeDataPerson: false })),
          dateofacts: prevDetails.dateofacts.map(item => ({ ...item, activeDataOfActs: false })),
        };
      });
    } else {
      setActiveDateId(clickedItem.uniqueId);
      const companyId = clickedItem.companyId;

      if (!companyId) {
        console.error('Company ID is invalid:', clickedItem);
        return;
      }

      setDetails(prevDetails => {
        console.log('Updating details:', prevDetails);
        return {
          ...prevDetails,
          shares: prevDetails.shares.map(item => ({
            ...item,
            activeDataShare: item.companyId === companyId,
          })),
          capitals: prevDetails.capitals.map(item => ({
            ...item,
            activeDataCapital: item.companyId === companyId,
          })),
          registrationoffices: prevDetails.registrationoffices.map(item => ({
            ...item,
            activeDataRegistration: item.companyId === companyId,
          })),
          addresses: prevDetails.addresses.map(item => ({
            ...item,
            activeDataAddress: item.companyId === companyId,
          })),
          purposes: prevDetails.purposes.map(item => ({
            ...item,
            activeDataPurpose: item.companyId === companyId,
          })),
          remarks: prevDetails.remarks.map(item => ({
            ...item,
            activeDataRemark: item.companyId === companyId,
          })),
          cities: prevDetails.cities.map(item => ({
            ...item,
            activeDataCity: item.companyId === companyId,
          })),
          persons: prevDetails.persons.map(item => ({
            ...item,
            activeDataPerson: item.companyId === companyId,
          })),
          dateofacts: prevDetails.dateofacts.map(item => ({
            ...item,
            activeDataOfActs: item.dateOfActs === clickedItem.dateOfActs,
          })),
        };
      });
    }
  } catch (error) {
    console.error('Error toggling Date of Acts:', error);
  }
};


  const getDetails = async () => {
    const token = localStorage.getItem('TOKEN');
    try {

      const companyResponse = await axios.get(COMPANY_API.getCompanyById(id), {
        headers: { Authorization: `Bearer ${token}` },
      });
      const companyData = companyResponse.data.data || {};
      setDetails(companyData); 
      setCompany(companyData);
  
      const initialNodes = [
        {
          id: companyData.companyName || 'Company Name',
          label: companyData.companyName || 'Company Name',
          image: companyData.logo || "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
          shape: 'circularImage',
        },
        ...(companyData.persons || []).map(person => ({
          id: person.id,  
          label: person.personalData || 'Unknown',
          image: "https://cdn-icons-png.flaticon.com/512/3033/3033143.png",
          shape: 'circularImage',
        })),
      ];
      
      const initialLinks = [
        ...(companyData.persons || []).map(person => ({
          from: companyData.companyName || 'Company Name',
          to: person.id,  
        })),
      ];

  
      setData({ nodes: initialNodes, links: initialLinks });
  
     
      const sectorsResponse = await axios.get(SECTOR_API.getAllSectors, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sectorData = sectorsResponse.data || [];
      setSectors(sectorData); 
  
      
      inferCompanySector(companyData, sectorData);
  
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  

  const inferCompanySector = (companyData, sectorData) => {
    console.log("Company Data:", companyData);
    console.log("Available Sectors:", sectorData);

    if (!companyData || !Array.isArray(companyData.purposes) || companyData.purposes.length === 0) {
        setSectorInfo("Sector information is not available.");
        return;
    }

    if (!sectorData || !Array.isArray(sectorData.data)) {
        setSectorInfo("Sector data is not available or not formatted correctly.");
        return;
    }

    const sectorIds = new Set(); 
    const duplicates = new Set();



    const inferredSectorData = [];

    companyData.purposes.forEach(purpose => {
        console.log(`Checking purpose with sectorId: ${purpose.sectorId}`);
        if (purpose.sectorId) {
            const sector = sectorData.data.find(sec => sec.id === purpose.sectorId);
            if (sector) {
                if (sectorIds.has(sector.name)) {
                    inferredSectorData.push({ id: sector.id, name: sector.name });
                    console.log(`Sector inferred data`, inferredSectorData)
                    console.warn(`Found duplicate sector: ${sector.name}`);
                    duplicates.add(sector.name);
                } else {
                    console.log(`Found sector for ID ${purpose.sectorId}: ${sector.name}`);
                    sectorIds.add(sector.name);
                }
            } else {
                console.warn(`No matching sector found for sectorId: ${purpose.sectorId}`);
            }
        } else {
            console.warn("Sector ID not found in purpose:", purpose);
        }
    });

    setInferredSectors(inferredSectorData);

    const sectorNames = Array.from(sectorIds);
    console.log("Sector names:", sectorNames);
    if (duplicates.size > 0) {
        console.log("Duplicate sectors found:", Array.from(duplicates).join(", "));
    }
    
    setSectorInfo(sectorNames.length > 0 ? sectorNames.join(", ") : "No sector info available");
};


  

  useEffect(() => {
    getDetails();
  }, [id]);

  useEffect(() => {
    if (data.nodes.length && data.links.length) {
      const nodes = new DataSet(data.nodes);
      const edges = new DataSet(data.links);
      const container = containerRef.current;

      const networkData = {
        nodes: nodes,
        edges: edges,
      };

      const options = {
        nodes: { shape: 'dot', size: 30 },
        edges: { smooth: { type: 'continuous' } },
        physics: { enabled: true },
      };

      networkInstance = new Network(container, networkData, options);

      // Handle node click events
      networkInstance.on("selectNode", async (params) => {
        const selectedNodeId = params.nodes[0];
        handleNodeClick(selectedNodeId);
      });
    }

    // Cleanup function to destroy the network instance
    return () => {
      if (networkInstance) {
        networkInstance.destroy();
      }
    };
  }, [data]);

  // Handle node click and fetch additional companies if needed
  const handleNodeClick = async (selectedNodeId) => {
    console.log("Selected node id", selectedNodeId);
    try {
      const token = localStorage.getItem('TOKEN');
  
      // Fetch companies using the clicked node's ID
      const response = await axios.get(`https://api.kseco.eu/api/owner-companies`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { id: selectedNodeId }  // Use the selectedNodeId directly
      });
  
      const companies = response.data.data || [];
      if (companies.length > 0) {
        updateNetworkWithNewCompanies(companies, selectedNodeId);
      } else {
        console.log("error")
      }
    } catch (error) {
      console.error('Failed to fetch companies for the owner:', error);
    }
  };

  
  // const updateNetworkWithNewCompanies = (companies, ownerPersonalData) => {
  //   const existingNodeIds = data.nodes.map(node => node.id);  // Get existing node IDs to prevent duplicates

  //   const newNodes = companies
  //     .filter(company => !existingNodeIds.includes(`${company.companyName || 'Unknown'}-company`))
  //     .map((company, index) => ({
  //       id: `${company.companyName || 'Unknown'}-${index}-company`,
  //       label: company.companyName || 'Unknown',
  //       shape: 'circularImage',
  //       image: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
  //     }));

  //   const newLinks = companies.map((company, index) => ({
  //     from: ownerPersonalData,
  //     to: `${company.companyName || 'Unknown'}-${index}-company`,
  //   }));

  //   setData(prevData => ({
  //     nodes: [...prevData.nodes, ...newNodes],
  //     links: [...prevData.links, ...newLinks],
  //   }));
  // };

  const updateNetworkWithNewCompanies = (companies, ownerPersonalData) => {
    const existingNodeIds = new Set(data.nodes.map(node => node.id));

    const newNodes = companies
      .filter(company => !existingNodeIds.has(company.id))
      .map(company => ({
        id: company.id || `${company.companyName}-${company.companyId}-company`,
        label: company.companyName || 'Unknown',
        shape: 'circularImage',
        image: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
      }));

    const newLinks = companies.map(company => ({
      from: ownerPersonalData,
      to: company.id || `${company.companyName}-${company.companyId}-company`,
    }));

    if (newNodes.length === 0) {
      addNotification("This person only has one company, which is already displayed.", "info");
    } else {
      setData(prevData => ({
        nodes: [...prevData.nodes, ...newNodes],
        links: [...prevData.links, ...newLinks],
      }));
    }
  };
  
  
  console.log(details.dateofacts);

  const toggleActiveDateId = (personId) => {
    console.log(`Toggling active state for person with ID: ${personId}`);
  
    setDetails(prevDetails => ({
      ...prevDetails,
      persons: prevDetails.persons.map(item => ({
        ...item,
        activeDataPerson: item.id === personId ? !item.activeDataPerson : item.activeDataPerson,
      })),
    }));
  };
  
  // function Details() {
  //   const [data, setData] = useState({
  //     nodes: [],
  //     links: [],
  //   });
    
  //   const [details, setDetails] = useState({});
  //   const [sectors, setSectors] = useState([]);
  //   const [company, setCompany] = useState({});
  //   const [currentCompany, setCurrentCompany] = useState({});
  //   const { id } = useParams();
    
  //   const containerRef = React.useRef(null);
  //   let networkInstance = null; // To hold the vis.Network instance
  
  //   const getDetails = async () => {
  //     const token = localStorage.getItem('TOKEN');
  //     try {
  //       const response = await axios.get(COMPANY_API.getCompanyById(id), {
  //         headers: { Authorization: `Bearer ${token}` },
  //       });
  //       const data = response.data.data || {};
  //       setDetails(data);
    
  //       // Set initial nodes and links for the graph based on company and persons
  //       const initialNodes = [
  //         {
  //           id: data.companyName || 'Company Name',
  //           label: data.companyName || 'Company Name',
  //           image: data.logo || "https://cdn-icons-png.flaticon.com/512/2891/2891526.png",
  //           shape: 'circularImage'
  //         },
  //         ...(data.persons || []).map((item, index) => ({
  //           id: `${item.personalData || 'Unknown'}-${index}`,
  //           label: item.personalData || 'Unknown',
  //           image: "https://cdn-icons-png.flaticon.com/512/3033/3033143.png",
  //           shape: 'circularImage'
  //         }))
  //       ];
    
  //       const initialLinks = [
  //         ...(data.persons || []).map((item, index) => ({
  //           from: data.companyName || 'Company Name',
  //           to: `${item.personalData || 'Unknown'}-${index}`
  //         }))
  //       ];
    
  //       // Update the graph data
  //       setData({ nodes: initialNodes, links: initialLinks });
    
  //     } catch (error) {
  //       console.error('Failed to fetch company details:', error);
  //     }
  //   };
    
  //   const getSectors = async () => {
  //     try {
  //       const response = await axios.get(SECTOR_API.getAllSectors);
  //       setSectors(response.data || []);
  //     } catch (error) {
  //       console.error('Failed to fetch sectors:', error);
  //     }
  //   };
    
  //   useEffect(() => {
  //     getDetails();
  //     getSectors();
  //   }, [id]);
  
  //   useEffect(() => {
  //     if (data.nodes.length && data.links.length) {
  //       // Initialize vis-network once the data is ready
  //       const nodes = new DataSet(data.nodes);
  //       const edges = new DataSet(data.links);
  //       const container = containerRef.current;
  
  //       const networkData = {
  //         nodes: nodes,
  //         edges: edges,
  //       };
  
  //       const options = {
  //         nodes: {
  //           shape: 'dot',
  //           size: 30,
  //         },
  //         edges: {
  //           smooth: {
  //             type: 'continuous',
  //           },
  //         },
  //         physics: {
  //           enabled: true,
  //         },
  //       };
  
  //       networkInstance = new Network(container, networkData, options);
  
  //       // Handle node click events
  //       networkInstance.on("selectNode", async (params) => {
  //         const selectedNodeId = params.nodes[0];
  //         handleNodeClick(selectedNodeId);
  //       });
  //     }
  
  //     // Cleanup function to destroy the network instance
  //     return () => {
  //       if (networkInstance) {
  //         networkInstance.destroy();
  //       }
  //     };
  //   }, [data]);
  
  //   const handleNodeClick = async () => {
  //     // Temporarily hardcoded ID to test the API
  //     const hardcodedId = "528cff3b-236c-40c6-8be7-6b0b2b541ea4";
    
  //     try {
  //       const token = localStorage.getItem('TOKEN');
    
  //       // Use GET request and include the ID in the query parameters
  //       const response = await axios.get(`https://api.kseco.eu/api/owner-companies`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //         params: { id: hardcodedId } // Set the ID as a query parameter
  //       });
    
  //       const companies = response.data.data || [];

  //       console.log(companies)
  //       if (companies.length > 0) {
  //         // Update the graph with new companies
  //         updateNetworkWithNewCompanies(companies, hardcodedId);
  //       } else {
  //         console.log('No companies found for this owner.');
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch companies for the owner:', error);
  //     }
  //   };
    
    
    
    
    
    
  
  //   const updateNetworkWithNewCompanies = (companies, ownerPersonalData) => {
  //     const newNodes = companies.map((company, index) => ({
  //       id: `${company.companyName || 'Unknown'}-${index}-company`,
  //       label: company.companyName || 'Unknown',
  //       shape: 'circularImage',
  //       image: "https://cdn-icons-png.flaticon.com/512/2891/2891526.png"
  //     }));
  
  //     const newLinks = companies.map((company, index) => ({
  //       from: ownerPersonalData,
  //       to: `${company.companyName || 'Unknown'}-${index}-company`,
  //     }));
  
  //     setData(prevData => ({
  //       nodes: [...prevData.nodes, ...newNodes],
  //       links: [...prevData.links, ...newLinks],
  //     }));
  //   };
  

  
  return (
    <div className="">
      <div className="no-printme">
        <Navbar />
      </div>
      <div className="container">
        <div className="water">
          <div className="row all printme" id="pdf">
            <div className="col-lg-12">
              <div className="card text-center"></div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        <i className="bi bi-clipboard deti de"></i>
                        &nbsp;&nbsp;{company?.companyName}
                      </h5>
                    </div>
                    <div className="card-body uid">
                      <p className="card-text">
                        <p><b>Unique ID : </b>{company?.che}</p>
                      </p>
                      <p className="card-text" style={{ marginRight: '30px' }}>
                        <p><b>Date of registration :</b> {company?.dateOfRegistration}</p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 id">
                  <div className="card">
                    <div
                      className="card-body but"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className="card-text" style={{ marginRight: '30px' }}>
                        <button
                          className="pdf no-printme"
                          onClick={() => window.print()}
                        >
                          <i class="bi bi-file-earmark-pdf pdfff"></i>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">In</th>
                            <th scope="col">
                              <i
                                className="bi bi-building deti"
                                style={{ marginBottom: '-5px' }}
                              ></i>
                              &nbsp;Bussines name
                            </th>
                            <th scope="col">Legal Form</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr  
                            style={{ borderBottom: '1px solid #dee2e6' }}
                            >
                            <th scope="row">{company.activeData}</th>
                            <td>
                              <p className={!currentCompany.activeData ? 'stike' : ''}>{company.companyName}</p>
                            </td>
                            <td>{company.companyType}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                
                <div className="col-sm-3" style={{ marginTop: '10px' }}>
                  <div className="card">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">Ref</th>
                          <th scope="col">
                            <i
                              className="bi bi-house deti "
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;Legal Seat
                          </th>
                        </tr>
                      </thead>
                      <tbody>
    {(details?.cities || []).length > 0 ? (
      details.cities.map((item, index) => (
        <tr
          style={{ borderBottom: '1px solid #dee2e6' }}
          key={item.uniqueId}
          className={item.activeDataCity ? 'active-row' : 'inactive-row'} // Conditional class for styling
        >
          <th scope="row">{index + 1}</th>
          <td>
            <p className={item.activeDataCity ? '' : 'stike'}>
              {item.city}
            </p>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="2">No Legal Seats Available</td>
      </tr>
    )}
  </tbody>
                    </table>
                  </div>
                </div>
              </div>

                <div className="row">
                <div className="col-sm-5" style={{ marginTop: '20px' }}>
  <div className="card">
    <div className="card-body">
      <table className="table detaj">
        <thead>
          <tr>
            <th scope="col">Ref</th>
            <th scope="col">
              <i className="bi bi-geo deti" style={{ marginBottom: '-5px' }}></i>
              &nbsp;Address
            </th>
          </tr>
        </thead>
        <tbody>
          {(details?.addresses || []).length > 0 ? (
            (details?.addresses || []).map((item, index) => (
              <tr
                style={{ borderBottom: '1px solid #dee2e6' }}
                key={`${item.uniqueId}-${index}`}
                className={item.activeDataAddress ? 'active-row' : 'inactive-row'}
              >
                <th scope="row">{index + 1}</th>
                <td>
                  <p className={item.activeDataAddress ? '' : 'stike'}>
                    {item.address}
                  </p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No Addresses Available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>


  <div className="col-sm-3" style={{ marginTop: '20px' }}>
    <div className="card">
      <div className="card-body">
        <table className="table detaj">
          <thead>
            <tr>
              <th scope="col">In</th>
              <th scope="col">
                <i className="bi bi-journal-check deti"></i>&nbsp;Date of Acts
              </th>
            </tr>
          </thead>
          {/* <tbody>
  {(details?.dateofacts || []).length > 0 ? (
    [...new Map(details.dateofacts.map(item => [item.id, item])).values()].map((item, index) => (
      <tr
        style={{ borderBottom: '1px solid #dee2e6' }}
        key={item.uniqueId} // Use uniqueId for keys
        onClick={() => toggleActiveDateId(item.id)}
      >
        <th scope="row">{index + 1}</th>
        <td
          className={!item.activeDataOfActs ? 'stike' : ''}
          style={{ color: activeDateId === item.id ? 'blue' : 'black' }}
        >
          {item.dateOfActs}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="2">No Date of Acts Available</td>
    </tr>
  )}
</tbody> */}

<tbody>
  {(details?.dateofacts || []).length > 0 ? (
    [...new Map(details.dateofacts.map(item => [item.dateOfActs, item])).values()].map((item, index) => (
      <tr
        style={{ borderBottom: '1px solid #dee2e6' }}
        key={`${item.uniqueId}-${item.dateOfActs}`}
        onClick={() => toggleActiveDate(item)}
      >
        <th scope="row">{index + 1}</th>
        <td className={!item.activeDataOfActs ? 'stike' : ''} style={{ color: activeDateId === item.uniqueId ? 'blue' : 'black' }}>
          {item.dateOfActs}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="2">No Date of Acts Available</td>
    </tr>
  )}
</tbody>









        </table>
      </div>
    </div>
  </div>

  {/* New Sector Table */}
  <div className="col-sm-4" style={{ marginTop: '20px' }}>
  <div className="card">
    <div className="card-body">
      <table className="table detaj">
        <thead>
          <tr>
            <th scope="col">Sector ID</th>
            <th scope="col">
              <i className="bi bi-cpu"></i> Sector Name
            </th>
          </tr>
        </thead>
        <tbody>
          {inferredSectors.length > 0 ? (
            inferredSectors.map((sector, index) => (
              <tr
                key={sector.id}
                className={sector.activeDataSector ? 'active-row' : 'inactive-row'}
              >
                <th scope="row">{sector.id}</th>
                <td>
                  <p className={sector.activeDataSector ? '' : 'stike'}>
                    {sector.name}
                  </p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No Sectors Available</td>
            </tr>
          )}
        </tbody>

      </table>
    </div>
  </div>
</div>

</div>



              

<div className="row">

<div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <table className="table detaj">
              <thead>
                <tr>
                  <th scope="col">In</th>
                  <th scope="col">
                    <i className="bi bi-coin"></i>&nbsp;Shares
                  </th>
                </tr>
              </thead>
              <tbody>
                {details?.shares?.length > 0 ? (
                  details.shares.map((item, index) => (
                    <tr
                      style={{ borderBottom: '1px solid #dee2e6' }}
                      key={`${item.uniqueId}-${index}`}
                      className={item.activeDataShare ? 'active-row' : 'inactive-row'}
                    >
                      <th scope="row">{index + 1}</th>
                      <td>
                        <p className={item.activeDataShare ? '' : 'stike'}>
                          {item.shares}
                        </p>
                      </td>
                      <td>
                        <p className={item.activeDataShare ? '' : 'stike'}>
                          {item.partner}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No Share Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Capital Table */}
      <div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <table className="table detaj">
              <thead>
                <tr>
                  <th scope="col">In</th>
                  <th scope="col">
                    <i className="bi bi-currency-euro"></i>&nbsp;Capital
                  </th>
                </tr>
              </thead>
              <tbody>
                {details?.capitals?.length > 0 ? (
                  details.capitals.map((item, index) => (
                    <tr
                      style={{ borderBottom: '1px solid #dee2e6' }}
                      key={`${item.uniqueId}-${index}`}
                      className={item.activeDataCapital ? 'active-row' : 'inactive-row'}
                    >
                      <th scope="row">{index + 1}</th>
                      <td>
                        <p className={item.activeDataCapital ? '' : 'stike'}>
                          {item.capital}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No Capital Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Registration Offices Table */}
      <div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <table className="table detaj">
              <thead>
                <tr>
                  <th scope="col">In</th>
                  <th scope="col">
                    <i className="bi bi-briefcase deti"></i>&nbsp;Registration Office
                  </th>
                </tr>
              </thead>
              <tbody>
                {details?.registrationoffices?.length > 0 ? (
                  details.registrationoffices.map((item, index) => (
                    <tr
                      style={{ borderBottom: '1px solid #dee2e6' }}
                      key={`${item.uniqueId}-${index}`}
                      className={item.activeDataRegistration ? 'active-row' : 'inactive-row'}
                    >
                      <th scope="row">{index + 1}</th>
                      <td>
                        <p className={item.activeDataRegistration ? '' : 'stike'}>
                          {item.officialPublication}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No Registration Offices Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

</div>


<div className="row">
  <div className="col-sm-12">
    <div className="card">
      <div className="card-body">
        <table className="table detaj">
          <thead>
            <tr>
              <th scope="col">In</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            {(details?.purposes || []).map((item, index) => (
              <tr
                style={{ borderBottom: '1px solid #dee2e6' }}
                key={index}
                className={item.activeDataPurpose ? 'active-row' : 'inactive-row'} // Apply class based on active state
              >
                <th scope="row">{index + 1}</th>
                <td>
                  <p className={item.activeDataPurpose ? '' : 'strike'}>{item.Purpose}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">In</th>
                            <th scope="col">
                              <i
                                className="bi bi-bookmark deti"
                                style={{ marginBottom: '-5px' }}
                              ></i>
                              &nbsp;Remarks
                            </th>
                          </tr>
                        </thead>
                        <tbody>
            {details?.remarks?.length > 0 ? (
              details.remarks.map((item, index) => (
                <tr
                  style={{ borderBottom: '1px solid #dee2e6' }}
                  key={`${item.uniqueId}-${index}`}
                  className={item.activeDataRemark ? 'active-row' : 'inactive-row'} // Apply class based on active state
                >
                  <th scope="row">{index + 1}</th>
                  <td>
                    <p className={item.activeDataRemark ? '' : 'strike'}>{item.remark}</p> {/* Use the strike class conditionally */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No Remarks Available</td>
              </tr>
            )}
          </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table last detaj">
                        <thead>
                          <tr>
                            <th scope="col">Ref</th>
                            <th scope="col">Journal</th>
                            <th scope="col">Date</th>
                            <th scope="col">SOGC</th>
                            <th scope="col">Date SOGC</th>
                            <th scope="col">Page ID</th>
                          </tr>
                        </thead>
                        <tbody>
                        {(details?.publications || []).map((item,index) => ( <tr  
                              style={{ borderBottom: '1px solid #dee2e6' }}
                              key={`${item.uniqueId}-${index}`}
                              >
                              <th scope="row">{index + 1}</th>
                            <td>{item.journalNumber}</td>
                            <td>{item.journalDate}</td>
                            <td>{item.SOGCNumber}</td>
                            <td>{item.SOGCDate}</td>
                            <td>
                              <a href="#" className='pageid'>{item.Page}</a>
                            </td>
                            </tr>))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12" style={{ marginTop: '20px' }}>
                <div className="card">
                <div className="card-body">
                  <table className="table detaj">
                    <thead>
                      <tr>
                        <th scope="col">In</th>
                        <th scope="col">Additional Payments</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(details?.additonalPayments || []).map((item,index) => ( <tr 
                         style={{ borderBottom: '1px solid #dee2e6' }}
                         key={`${item.uniqueId}-${index}`}
                         >
                          <th scope="row">{index + 1}</th>
                          <td className={!item.activeDataPayment ? 'stike' : ''}>
                            {item.additonalPayment}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tbody></tbody>
                  </table>
                </div>
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">In</th>
                          <th scope="col">Qualited Facts</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">In</th>
                          <th scope="col">
                            <i
                              className="bi bi-people deti"
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;Personal Data
                          </th>
                          <th scope="col">Function</th>
                          <th scope="col">
                            <i
                              className="bi bi-pencil deti"
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;Signature
                          </th>
                        </tr>
                      </thead>
                      <tbody>
  {(details?.persons || []).map((item, index) => (
    <tr
      style={{ borderBottom: '1px solid #dee2e6' }}
      key={`${item.uniqueId}-${index}`} // Use a unique key
      className={item.activeDataPerson ? 'active-row' : 'inactive-row'} // Conditional class for styling
      onClick={() => toggleActiveDateId(item.uniqueId)} // Pass uniqueId or relevant identifier
    >
      <th scope="row">{index + 1}</th>
      <td>
        <p className={!item.activeDataPerson ? 'stike' : ''}>
          {item.personalData}
        </p>
      </td>
      <td>{item.function}</td>
      <td>{item.signature}</td>
    </tr>
  ))}
</tbody>



                    </table>
                    {/* { data.nodes.length > 0 && data.links.length > 0 ? (
                      <Graph
                        id="graph-id"
                        data={data}
                        config={myConfig}
                        
                      /> 
                    ) : (
                      <p>Loading graph...</p>
                    )} */}
                    <div ref={containerRef} style={{ width: '100%', height: '800px'}} />
                    {data.nodes.length === 0 && data.links.length === 0 && <p>Loading graph...</p>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
