import React, {useState } from 'react'
import Chart from 'react-apexcharts';

const Bar=() => {
    const [options] = useState({
        chart: {
            height: 350,
            type: 'line',
          },
          stroke: {
            width: [0, 4]
          },
          colors : ['#8ab3e4', '#21538f'],
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
          },
          labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
          xaxis: {
            type: 'datetime'
          },
          responsive: [
            {
              breakpoint: 280,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ],
          yaxis: [{
            title: {
              text: 'Website Blog',
            },
          
          }, {
            opposite: true,
            title: {
              text: 'Social Media'
            }
          }]
    })

    const [series] = useState([{
        name: 'Website Blog',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'Social Media',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }])


      return (
        

  <div id="chart">
<Chart options={options} series={series} type="line" height={400} width={600}/>
</div>

      );
    }

export default Bar;