// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { NATIONALITY_API } from '../../api/api';

// const NationalityFilterDashboard = ({ onFilterChange }) => {
//   const [nationalities, setNationalities] = useState([]);
//   const [selectedNationalityIds, setSelectedNationalityIds] = useState([]);

//   useEffect(() => {
//     const fetchNationalities = async () => {
//       const token = localStorage.getItem("TOKEN");
//       try {
//         const response = await axios.get(NATIONALITY_API.getAllNationalities, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setNationalities(response.data.data);
//       } catch (error) {
//         console.error("Error fetching nationalities:", error);
//       }
//     };

//     fetchNationalities();
//   }, []);

//   const handleCheckboxChange = (event) => {
//     const { value, checked } = event.target;
//     const nationalityId = parseInt(value);
//     let updatedSelectedNationalityIds;

//     if (checked) {
//       updatedSelectedNationalityIds = [...selectedNationalityIds, nationalityId];
//     } else {
//       updatedSelectedNationalityIds = selectedNationalityIds.filter(id => id !== nationalityId);
//     }

//     setSelectedNationalityIds(updatedSelectedNationalityIds);
//     onFilterChange('nationality', updatedSelectedNationalityIds); // Update filter state with selected nationality IDs
//   };

//   return (
//     <li className="dropright sidebar-item nh">
//       <a href="#" className="dropdown-toggle sidebar-title" data-toggle="dropdown">
//         <i className="bi bi-flag cit"></i> Nationality
//       </a>
//       <div className="dropdown-menu">
//         <ul className="list-unstyled">
//           {nationalities.map(nationality => (
//             <li className="drop" key={nationality.id}>
//               <label>
//                 <input 
//                   type="checkbox" 
//                   value={nationality.id}  // Use nationality ID for filtering
//                   onChange={handleCheckboxChange} 
//                   checked={selectedNationalityIds.includes(nationality.id)} 
//                 />
//                 {nationality.nationality}
//               </label>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </li>
//   );
// };

// export default NationalityFilterDashboard;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NATIONALITY_API } from '../../api/api';
import styles from './NationalityFilterDashboard.module.css'; // Import the CSS module

const NationalityFilterDashboard = ({ onFilterChange }) => {
  const [nationalities, setNationalities] = useState([]);
  const [selectedNationalityIds, setSelectedNationalityIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchNationalities = async () => {
      const token = localStorage.getItem("TOKEN");
      try {
        const response = await axios.get(NATIONALITY_API.getAllNationalities, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNationalities(response.data.data);
      } catch (error) {
        console.error("Error fetching nationalities:", error);
      }
    };

    fetchNationalities();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const nationalityId = parseInt(value);
    let updatedSelectedNationalityIds;

    if (checked) {
      updatedSelectedNationalityIds = [...selectedNationalityIds, nationalityId];
    } else {
      updatedSelectedNationalityIds = selectedNationalityIds.filter(id => id !== nationalityId);
    }

    setSelectedNationalityIds(updatedSelectedNationalityIds);
    onFilterChange('nationality', updatedSelectedNationalityIds); // Update filter state with selected nationality IDs
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className={`${styles.sidebarItem} ${isOpen ? styles.open : ''}`}>
      <div className={styles.sidebarTitle} onClick={toggleDropdown}>
        <div>
          <i className="bi bi-flag cit"></i> 
          <span>Nationality</span>   
        </div>
        <i className="bi-chevron-down toggle-btn"></i>

      </div>
      <div className={`${styles.dropdownMenu} ${isOpen ? styles.open : ''}`}>
        <ul className={styles.list}>
          {nationalities.map(nationality => (
            <li className={styles.listItem} key={nationality.id}>
              <label>
                <input 
                  type="checkbox" 
                  className={styles.checkbox}
                  value={nationality.id}  // Use nationality ID for filtering
                  onChange={handleCheckboxChange} 
                  checked={selectedNationalityIds.includes(nationality.id)} 
                />
                {nationality.nationality}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default NationalityFilterDashboard;

