import { useState } from "react";

const SectorFilter = ({ onFilterChange, sectors }) => {
    const [selectedSectors, setSelectedSectors] = useState([]);
  
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      let updatedSelectedSectors;
  
      if (checked) {
        updatedSelectedSectors = [...selectedSectors, value];
      } else {
        updatedSelectedSectors = selectedSectors.filter(sector => sector !== value);
      }
  
      setSelectedSectors(updatedSelectedSectors);
      onFilterChange('purposes', updatedSelectedSectors); // Ensure 'purposes' matches filter key
    };
  
    return (
      <li className="dropright sidebar-item nh">
        <a href="#" className="dropdown-toggle sidebar-title" data-toggle="dropdown">
          <i className="bi bi-flag cit"></i> Sector
        </a>
        <div className="dropdown-menu">
          <ul className="list-unstyled">
            {sectors.map(sector => (
              <li className="drop" key={sector.name}>
                <label>
                  <input
                    type="checkbox"
                    value={sector.name}
                    onChange={handleCheckboxChange}
                    checked={selectedSectors.includes(sector.name)}
                  />
                  {sector.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  };
  

export default SectorFilter;