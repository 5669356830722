import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NATIONALITY_API } from '../../api/api'; // Ensure this is the correct path

const NationalityFilter = ({ onFilterChange }) => {
    const [nationalities, setNationalities] = useState([]);
    const [selectedNationalityIds, setSelectedNationalityIds] = useState([]);

    useEffect(() => {
        // Fetch the list of nationalities from the API
        const fetchNationalities = async () => {
            const token = localStorage.getItem("TOKEN");
            try {
                const response = await axios.get(NATIONALITY_API.getAllNationalities, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setNationalities(response.data.data);
            } catch (error) {
                console.error("Error fetching nationalities:", error);
            }
        };

        fetchNationalities();
    }, []);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const nationalityId = parseInt(value);
        let updatedSelectedNationalityIds;

        if (checked) {
            updatedSelectedNationalityIds = [...selectedNationalityIds, nationalityId];
        } else {
            updatedSelectedNationalityIds = selectedNationalityIds.filter(id => id !== nationalityId);
        }

        setSelectedNationalityIds(updatedSelectedNationalityIds);
        onFilterChange('nationality', updatedSelectedNationalityIds);
    };

    return (
        <li className="dropright sidebar-item nh">
            <a href="#" className="dropdown-toggle sidebar-title" data-toggle="dropdown">
                <i className="bi bi-flag cit"></i> Nationality
            </a>
            <div className="dropdown-menu">
                <ul className="list-unstyled">
                    {nationalities.map(nationality => (
                        <li className="drop" key={nationality.id}>
                            <label>
                                <input 
                                    type="checkbox" 
                                    value={nationality.id}  // Use nationality ID for filtering
                                    onChange={handleCheckboxChange} 
                                    checked={selectedNationalityIds.includes(nationality.id)} 
                                />
                                {nationality.nationality}
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

export default NationalityFilter;
