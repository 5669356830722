import { Nav, Navbar, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import a from '../../images/logo.png';
import './navbar2.css';
import axios from 'axios';
import '../Login/login.css';

const Navigationbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const Logout = async () => {
    try {
      localStorage.removeItem('TOKEN');
      navigate('/');
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();


  const Auth = async (e) => {
    e.preventDefault();
    try {
      const login = await axios.post('https://api.kseco.ch/auth/login', {
        email: email,
        password: password,
      });
      localStorage.setItem('TOKEN', login.data.token);
      localStorage.setItem('user', JSON.stringify(users))
      navigate('/Dashboard');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const [users, setUsers] = useState(null); // Initialize users state as null

  // Call getUsers function in useEffect hook


  const getUsers = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data); // Log the response data to check its structure
      setUsers(response.data); // Update users state with response data
      console.log(users); // Log the users state variable to double-check its content
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };
  
  useEffect(() => {
    getUsers();
  }, []);
  
  console.log(users)

  const [isLogged, setisLogged] = useState(false);

  useEffect(() => {
    checkStorage();
    return () => {};
  }, [isLogged]);

  function checkStorage() {
    if (localStorage.getItem('TOKEN')) {
      setisLogged(true);
    } else {
      setisLogged(false);
    }
  }
  return (
    <div>
      <div class="header_top_menu pt-2 pb-2 bg_color navi2">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 col-sm-8">
					<div class="header_top_menu_address">
						<div class="header_top_menu_address_inner">
							<ul>
								<li><a href="#"><i class="bi bi-envelope"></i>example@gmail.com</a></li>
								<li><a href="#"><i class="bi bi-geo-alt-fill"></i>24/5, 1st Floor, Kurigram</a></li>
								<li><a href="#"><i class="bi bi-telephone"></i>+ (1800) 456 7890</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-4">
					<div class="header_top_menu_icon">
						<div class="header_top_menu_icon_inner">
							<ul>
								<li><a href="#"><i class="fa bi bi-facebook"></i></a></li>
								<li><a href="#"><i class="fa bi bi-twitter"></i></a></li>
								<li><a href="#"><i class="fa bi bi-pinterest"></i></a></li>
								<li><a href="#"><i class="fa bi bi-linkedin"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
      <Navbar collapseOnSelect expand="sm" variant="dark" className='navbar2'>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
        />
        <a href="/" className="brand-name">
          <img src={a} className="logoja"alt='' />
        </a>
        <Navbar.Collapse id="navbarScroll">
          <Nav>
            <NavLink eventKey="1" as={Link} to="/home" className='navlinka'>
              Home
            </NavLink>
            <NavLink eventKey="2" as={Link} to="#" className='navlinka'>
              About
            </NavLink>
            <NavLink eventKey="2" as={Link} to="#" className='navlinka'>
              Marketing
            </NavLink>
            {isLogged ? (
            <NavLink eventKey="2" as={Link} to="/Dashboard" className='lista navlinka'>
              <button className="button isi navlinka isa">
              <i class="icon-equalizer warning font-large-2 "></i><br/>List Builder
              </button>
            </NavLink>) : (
              <NavLink eventKey="2" as={Link} to={togglePopup} onClick={togglePopup} className='lista navlinka'>
                 <button className="isi navlinka isa">
              <i class="icon-equalizer warning font-large-2 "></i><br/>List Builder
              </button>
              </NavLink>)}

  
            {isLogged ? (
              <div>
             <div class="dropdown">
  <button class="button is-light dropdown-toggle huh" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  {users && (
            <b><i class="bi bi-person pir lo navlinka"></i> {users.data.name}</b>)}
  </button>
  <div class="dropdown-menu account" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">My account</a>
    <a class="dropdown-item" href="#">
    <button onClick={Logout} className="button is-light">
                <i className="bi bi-box-arrow-right lo"></i>
               Log out
              </button>
    </a>
  </div>
</div>
              </div>
            ) : (
              <button onClick={togglePopup} className="button is-light">
                <i class="bi bi-person per lo"></i>
               Login
              </button>
            )}
            
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      

      {isOpen && (
        <div className="popup-box ">
          <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <link
              href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
              rel="stylesheet"
            />
            <div className="login">
              <div className="form">
                <span className="close-icon" onClick={handleClose}>
                  x
                </span>
                <form className="login-form" onSubmit={Auth}>
                  <p className="has-text-centered">{msg}</p>
                  <span className="material-icons">
                    <i className="fa fa-lock"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button>Login</button>
                </form>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Navigationbar;
